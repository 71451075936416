<template>
  <v-app>
    <div>
      <v-data-table
          :headers="headers"
          :items="bookings"
          :search="search"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Rezervari</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="search"
                label="Cauta"
                single-line
                hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.room"
                            label="Camera"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.date"
                            label="Data"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.status"
                            label="Status"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Anuleaza
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Salveaza
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="makeItemAdmin(item)">
            mdi-account-check-outline
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<style lang="scss">
.v-data-footer__select {
  opacity: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { GET_MY_BOOKINGS } from "@/core/services/store/booking.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data: () => ({
    dialog: false,
    dialogAdmin: false,
    search: "",
    headers: [
      { text: "ID", value: "id" },
      {
        text: "Camera",
        align: "left",
        sortable: true,
        value: "room.data.name"
      },
      { text: "Data", value: "date_start" },
      { text: "Status", value: "status" }
    ],
    bookings: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vezi toate rezervarile" }]);
    this.$store.dispatch(GET_MY_BOOKINGS);
  },
  computed: {
    ...mapGetters(["currentBookingsInfo"]),
    getAllBookings() {
      return this.currentBookingsInfo.data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Editeaza Rezervare";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogAdmin(val) {
      val || this.closeDelete();
    }
  },

  created() {
    //console.log('created');
    this.initialize();
  },

  methods: {
    initialize() {
      this.bookings = this.$store.getters.currentBookingsInfo.data;
      //console.log("is?: ", this.$store.getters.currentBookingsInfo);
    },
    //
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      // this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeDelete() {
      // this.dialogAdmin = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    //
    save() {
      // if (this.editedIndex > -1) {
      //   Object.assign(this.users[this.editedIndex], this.editedItem);
      // } else {
      //   this.users.push(this.editedItem);
      // }
      //
      // var payload = this.editedItem;
      //
      // if (payload.status === "Activ") {
      //   payload.status = 1
      // } else {
      //   payload.status = 0;
      // }
      // //console.log(payload);
      //
      // this.close();
    }
  }
};
</script>
